<template>
  <div class="AlumniBook">
    <EduHeader />
    <main class="container">
      <AlumniBookAside />
      <AlumniBookContent />
    </main>
  </div>
</template>

<script>
import EduHeader from "@/components/EduHeader";
import AlumniBookAside from "./components/AlumniBookAside.vue";
import AlumniBookContent from "./components/AlumniBookContent.vue";
export default {
  name: "AlumniBook",
  components: {
    EduHeader,
    AlumniBookAside,
    AlumniBookContent,
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100vh;
  display: flex;
}
</style>
