<template>
  <div class="AlumniBookContent"></div>
</template>

<script>
export default {
  name: "AlumniBookContent",
};
</script>

<style lang="scss" scoped>
.AlumniBookContent {
  background-image: url("~@/assets/AlumniBook/dtbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  flex: 1;
}
</style>
