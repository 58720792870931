<template>
  <aside class="AlumniBookAside">
    <img src="~@/assets/AlumniBook/avatar.png" alt="" />
    <div class="desc">欢迎， 张三</div>
    <div class="avatars">
      <div class="list">
        <img
          v-for="num of 100"
          :key="num"
          src="~@/assets/AlumniBook/avatar.png"
          alt=""
        />
      </div>
    </div>
    <div class="btn">
      <img src="~@/assets/AlumniBook/book.png" alt="" />
      <span>学生名录</span>
    </div>
  </aside>
</template>

<script>
export default {
  name: "AlumniBookAside",
};
</script>

<style lang="scss" scoped>
.AlumniBookAside {
  width: 347px;
  border-right: 1px solid $color-border-0;
  font-size: 0;
  img {
    width: 100%;
    height: 347px;
  }

  .desc {
    font-size: 16px;
    color: $color-text-7;
    padding: 24px 30px;
    border-bottom: 1px solid $color-border-0;
  }

  .avatars {
    padding: 18px 40px 28px;
    border-bottom: 1px solid $color-border-0;
  }

  .list {
    width: 267px;
    height: 267px;
    overflow: auto;
    img {
      width: 35px;
      height: 35px;
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }

  .btn {
    img {
      width: 32px;
      height: 32px;
      margin-right: 19px;
    }
    border-radius: 6px;
    border: 1px solid $color-border-0;
    font-size: 16px;
    width: 165px;
    height: 65px;
    display: flex;
    align-items: center;
    margin-left: 40px;
    margin-top: 28px;
    padding-left: 15px;
  }
}
</style>
